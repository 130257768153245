<template>
  <div v-if="isPermission">
    <div class="row" style="margin: 5px">
      <div class="col-md-10 col-9">
        <h2 class="font-weight-normal">
          {{ $t('management.stockOutDoc') }}
        </h2>
      </div>
      <div class="col-md-2 col-3 text-right align-text-center">
        <a id="stockOutDetailBackLink" @click="$router.go(-1)">
          <CButton>
            <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
          </CButton>
        </a>
      </div>
    </div>

    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <CAlert color="success" v-if="isSuccess === true" id="stockOutDetailSuccessAlert">
            {{ $t('dialogUpdateDocumentSuccess') }}
          </CAlert>
          <div class="row">
            <div class="col-7 col-lg-10">
              <h4 class="font-weight-normal">
                {{ $t('information') }}
              </h4>
            </div>
            <div v-if="isExport" class="justify-content-end col-lg-2 col-5">
              <CButton id="stockOutDetailExportButton" v-on:click="exportReport()" block color="info">
                <i class="fi fi-rr-file-pdf"></i>  {{ $t('export') }}
              </CButton>
            </div>
          </div>
          <br />
          <div class="form-group row" v-if="isEdit === true">
            <label class="col-12 col-md-2 col-form-label text-right">
              {{ $t('dateOfBill') }}
            </label>
            <div class="col-12 col-md-4">
              <VueDatePicker id="stockOutDetailDatePicker" :disabled="disabledField" color="#29B46B" :locale="getDateLocale()"
                :visible-years-number="90" v-model="detail.date" format="DD-MM-YYYY" class="form-control text-dark">
                <template v-slot="{ inputValue, inputEvents }">
                  <input id="stockOutDetailDateInput"  :value="inputValue" v-on="inputEvents" :disabled="disabledField" />
                </template>
              </VueDatePicker>
            </div>
            <label class="col-12 col-md-2 col-form-label text-right">
              {{ $t('billNo') }}
            </label>
            <div class="col-12 col-md-4">
              <input id="stockOutDetailBillNoInput" class="form-control text-dark" v-model="detail.billNo" :placeholder="$t('billNo')"
                :disabled="disabledField" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-12 col-md-2 col-form-label text-right">
              {{ $t('shop') }}
            </label>
            <div class="col-12 col-md-4">
              <select id="stockOutDetailShopSelect" v-model="shopObjectId" class="custom-select text-dark font-weight-normal" disabled>
                <option :id="'stockOutDetailShopOption' + shop.objectId" v-for="shop in shops" :key="shop.objectId" :value="shop.objectId"
                  class="text-dark font-weight-normal">
                  {{ shop.shopName }} - {{ shop.branchName }}
                </option>
              </select>
            </div>
            <label class="col-12 col-md-2 col-form-label text-right">
              {{ $t('customer') }}
            </label>
            <div class="col-12 col-md-4">
              <!-- <select class="custom-select text-dark" :disabled="disabledField">
                <option selected>
                  {{ customer.name }}
                </option>
              </select> -->
              <multiselect id="stockOutDetailCustomerSelect" v-model="customer" :options="members" label="name" track-by="name" :disabled="disabledField"
                :placeholder="$t('customer')" :select-label="$t('pressEnterSelect')" :selected-label="$t('selected')"
                :deselect-label="$t('pressEnterRemove')">
              </multiselect>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-md-2 col-form-label text-right">
              {{ $t('reason') }}
            </label>
            <div class="col-12 col-md-10">
              <textarea id="stockOutDetailReasonTextarea" class="form-control text-dark" :value="detail.note" :disabled="disabledField" />
            </div>
          </div>
        </CCardBody>
      </CCard>

      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-6">
              <h4 class="font-weight-normal">
                {{ $t('stockOutList') }} - {{ detail.stockNo || '' }}
              </h4>
              <p class="text-dark">
                {{ $t('addstockOutList') }}
              </p>
            </div>
            <div class="col-md-4 text-right">
              <CButton id="stockOutDetailImportCheckStockButton" v-if="disabledField === false" style="display: flex; align-items: center; justify-content: center;" class="btn btn-outline-success btn-block"
                @click="AddCheckStockModal = true">
                <i class="fi fi-rr-plus" style="margin-right: 8px;"></i> {{ $t('importcheckStockDoc') }}
              </CButton>
            </div>
            <div class="col-md-2 text-right">
              <CButton id="stockOutDetailAddProductButton" v-if="disabledField === false" style="display: flex; align-items: center; justify-content: center;" class="btn btn-outline-success btn-block" @click="AddModal()">
                <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>{{ $t('addProduct') }}
              </CButton>
            </div>
          </div>

          <CRow class="mt-3">
            <CCol sm="12" lg="12">
              <table id="stockOutDetailTable" style="white-space: nowrap;" v-if="detail.items && detail.items.length != 0"
                class="table table-sm table-responsive table-bordered text-dark font-weight-normal">
                <thead>
                  <tr>
                    <th class="text-center font-weight-normal text-dark">#</th>
                    <th></th>
                    <th class="font-weight-normal text-dark">{{ $t('pluCode') }}</th>
                    <th class="font-weight-normal text-dark">{{ $t('productName') }}</th>
                    <th class="font-weight-normal text-dark">{{ $t('unit') }}</th>
                    <th class="text-right font-weight-normal text-dark">{{ $t('quantity') }}</th>
                    <th class="text-right font-weight-normal text-dark">{{ $t('stockUnit') }}</th>
                    <th class="text-right font-weight-normal text-dark">{{ $t('discount') }}</th>
                    <th class="text-right font-weight-normal text-dark">{{ $t('total') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in documentItems" :key="index">
                    <td :id="'stockOutDetailRowImage' + index" style="width:2%" class="text-center" :value="(item.orderIndex = index)">
                      <div style="margin-top: 6px">
                        {{ index + 1 }}
                      </div>
                    </td>
                    <td style="width:6%">
                      <div v-if="item.remoteImagePath" :style="{
    'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
    'background-size': 'cover',
    'background-position': 'center center',
    'width': 'auto',
    'border-radius': '2px',
    'aspect-ratio': '1.3',
    'min-width': '50px',
  }"></div>
                      <div v-else style="border-radius: 2px" :style="{
    'aspect-ratio': '4 / 3',
    'min-width': '50px',
    backgroundColor: item.indexColor,
  }"></div>
                    </td>
                    <td  :id="'stockOutDetailRowPLUCode' + index">
                      <div style="margin-top: 6px" v-if="item.productPLU.PLUCode === undefined">
                        {{ item.productPLU.PLUCode }}
                      </div>
                      <div style="margin-top: 6px" v-else>
                        {{ item.productPLU.PLUCode }}
                      </div>
                    </td>
                    <td style="width: 30%" :id="'stockOutDetailRowProductName' + index">
                      <div class="text-dark font-weight-normal" style="margin-top: 6px">
                        <span v-if=" item.vatType === 'N' || item.productSKU.vatType === 'N' ">{{ item.productPLU.name || '' }} <CBadge
                            color="danger">
                            Non VAT
                          </CBadge></span>
                        <span v-else> {{ item.productPLU.name || '' }} </span>
                      </div>
                      <span style="font-size:10px" v-if="item.isLastedAdjust === true" class="text-danger">
                        {{ $t('unableStockDescription') }}
                      </span>
                      <span style="font-size: 10px" v-if="isDeletedItem(item)" class="text-danger">
                        Deleted: {{ toDateString(item.deleted_at) }}
                      </span>
                    </td>
                    <td v-if="item.productPLU.unit === undefined" :id="'stockOutDetailRowUnit' + index" >
                      <div style="margin-top: 6px" v-if="item.productPLU.SKURatio == undefined">
                        - / -
                      </div>
                      <div style="margin-top: 6px" v-else >
                        - / {{ item.productPLU.SKURatio }}
                      </div>
                    </td>
                    <td v-else >
                      <div style="margin-top: 6px" v-if="item.productPLU.SKURatio == undefined">
                        {{ item.productPLU.unit.name }} / -
                      </div>
                      <div style="margin-top: 6px" v-else>
                        {{ item.productPLU.unit.name }} /
                        {{ item.productPLU.SKURatio }}
                      </div>
                    </td>
                    <td :id="'stockOutDetailRowQuantity' + index">
                      <input style="width: 100%; height: 100%" type="number" v-model.number="item.quantity"
                        class="form-control form-control-md text-right  font-weight-normal text-dark"
                        v-if="item.isLastedAdjust === true" disabled />
                      <input v-else style="width: 100%; height: 100%" type="number" v-model.number="item.quantity"
                        class="form-control form-control-md text-right font-weight-normal text-dark"
                        :disabled="disabledField || isDeletedItem(item)" />
                    </td>
                    <td :id="'stockOutDetailRowCost' + index">
                      <input style="width: 100%; height: 100%" type="number"
                        class="form-control form-control-md text-right font-weight-normal text-dark"
                        v-model.number="item.cost" v-if="item.isLastedAdjust === true" disabled />
                      <input v-else style="width: 100%; height: 100%" type="number"
                        class="form-control form-control-md text-right font-weight-normal text-dark"
                        v-model.number="item.cost" :disabled="disabledField || isDeletedItem(item)" />
                    </td>
                    <td :id="'stockOutDetailRowDiscount' + index">
                      <input style="width: 100%; height: 100%"
                        class="form-control form-control-md text-right font-weight-normal text-danger"
                        v-model.number="item.discount" v-if="item.isLastedAdjust === true" disabled />
                      <input style="width: 100%; height: 100%"
                        class="form-control form-control-md text-right font-weight-normal text-danger"
                        v-model.number="item.discount" :disabled="disabledField || isDeletedItem(item)" v-else />
                    </td>
                    <td :id="'stockOutDetailRowGrandtotal' + index">
                      <input style="width: 100%; height: 100%"
                        class="form-control form-control-md text-right font-weight-normal text-dark"
                        :value="grandTotalItem(item)" disabled />
                    </td>
                    <td class="text-center" style="width: 2%">
                      <CButton :id="'stockOutDetailRowLastedAdjust' + index" color="danger" size="sm" v-if="item.isLastedAdjust === true" disabled>
                        <CIcon name="cil-trash"></CIcon>
                      </CButton>
                      <CButton :id="'stockOutDetailRowRemove' + index" color="danger" size="sm" @click="removeProduct(item)"
                        :disabled="disabledField || isDeletedItem(item)" v-else>
                        <CIcon name="cil-trash"></CIcon>
                      </CButton>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="text-danger" v-if="warning != ''">{{ warning }}</p>
              <p v-if="duplicateItem != ''" class="text-danger">
                {{ duplicateItem }}
              </p>
            </CCol>
          </CRow>
          <div v-if="validateItem" class="text-danger" id="stockOutDetailValidateItem">
            {{ validateItem }}
          </div>
          <br />
          <div v-if="documentItems && documentItems.length > 0">
            <div class="row justify-content-end">
              <div class="col-md-3 col-6 text-right">
                <p id="stockOutDetailIsVatFalse" v-if="isVat === false">{{ $t('isVatIncluded') }}</p>
                <p id="stockOutDetailIsVatTrue" v-else-if="isVat === true">
                  {{ $t('vatIccludes') }}
                </p>
                <CSwitch id="stockOutDetailIsVatSwitch" v-model="isVat" color="success" :value="isVat" :checked.sync="isVat"
                  :disabled="disabledField" />
              </div>
              <div class="col-md-2 col-6 text-right">
                <p>{{ $t('totalNet') }}</p>
                <h4 class="font-weight-normal text-dark">{{ netTotalCurrencyString }}</h4>
              </div>
              <div class="col-md-2 col-6 text-right">
                <p>{{ $t('discount') }}</p>
                <h4 id="stockOutDetailTotalNetValue" class="text-right font-weight-normal text-danger " style="padding-left: 30px;">
                  <input style="margin-top: -5px;" v-model.number="detail.discount"
                    class="form-control form-control-md mx-auto text-right text-danger" :disabled="disabledField" />
                </h4>
              </div>
              <div class="col-md-2 col-6 text-right">
                <p>{{ $t('vat') }} 7%</p>
                <h4 id="stockOutDetailVatValue" class="font-weight-normal text-dark">{{ vatCurrencyString }}</h4>
              </div>
              <div class="col-md-2 col-6 text-right">
                <p>{{ $t('grandTotal') }}</p>
                <h4 id="stockOutDetailGrandTotalValue" class="font-weight-normal text-dark">{{ grandTotal }}</h4>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>

      <!-- Footer button -->
      <div class="row justify-content-end mb-4" style="margin: 0" v-if="disabledField === false">
        <CButton id="stockOutDetailConfirmButton" style="margin-right: 9px" class="btn btn-success col-lg-2 col" @click="popupModal = true" :disabled="items.length == 0">
          {{ $t('confirmStockOut') }}
        </CButton>
        <CButton id="stockOutDetailDeleteButton" class="btn col-lg-2 col btn-danger btn-block" @click="deleteModal = true"
          v-if="isRemove">
          {{ $t('deleteDoc') }}
        </CButton>
      </div>
    </CCol>

    <!-- Confirm modal-->
    <CRow>
      <CModal id="stockOutDetailPopupModal" :show.sync="popupModal" color="success" :title="$t('confirmSaveDoc')" centered :footer="footer">
        <br />
        <h4 class="text-center">
          {{ $t('confirmUpdateDocModal') }}
        </h4>
        <br />
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="6">
              <CButton id="stockOutDetailSaveButton" block color="success" v-if="loadingButton === true" v-on:click="formSubmit">
                {{ $t('save') }}
              </CButton>
              <CButton id="stockOutDetailSaveButtonDisabled" block color="success" v-else-if="loadingButton === false" disabled>
                <CSpinner color="white" size="sm" /> {{ $t('save') }}
              </CButton>
            </CCol>
            <CCol col="6">
              <CButton id="stockOutDetailCancelButton" block color="light" @click="popupModal = false">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>

    <!-- Delete confirm modal -->
    <CRow>
      <CModal id="stockOutDetailDeleteModal" :show.sync="deleteModal" color="danger" :title="$t('confirmDeleteDoc')" centered :footer="deleteFooter">
        <br />
        <h4 class="text-center">
          {{ $t('confirmDeleteModal') }}
        </h4>
        <br />
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="6">
              <CButton id="stockOutDetailConfirmDeleteButton" block color="danger" v-if="loadingButton === true"
                v-on:click="deleteStockDocument(stockOutObjectId)">
                {{ $t('confirm') }}
              </CButton>
              <CButton id="stockOutDetailConfirmDeleteButtonDisabled" block color="danger" v-else-if="loadingButton === false" disabled>
                <CSpinner color="white" size="sm" /> {{ $t('confirm') }}
              </CButton>
            </CCol>
            <CCol col="6">
              <CButton id="stockOutDetailCancelDeleteButton" block color="light" @click="deleteModal = false">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>

    <!-- Select Product modal -->
    <CRow>
      <CModal id="stockOutDetailAddProductModal" :show.sync="AddProductModal" centered :footer="footer" size="lg" style="max-width: 915px">
        <template #header-wrapper class="header-modal">
          <header class="modal-header header-modal">
            <h4 class="modal-title font-weight-normal">
              {{ $t('selectdProduct') }}
            </h4>
            <button id="stockOutDetailCloseButton" type="button" aria-label="Close" class="close" @click="AddProductModal = false">
              ×
            </button>
          </header>
        </template>
        <template #body-wrapper>
          <CCardBody>
            <CRow>
              <label class="col-sm-1 col-form-label text-left text-dark font-weight-normal">
                {{ $t('search') }}
              </label>
              <CCol style="padding-right: 0;">
                <select id="stockOutDetailSearchBy" v-model="searchBy" class="custom-select no-border-radius-left">
                  <option value="PLUCode">{{ $t('pluCode') }}</option>
                  <option value="name">{{ $t('productName') }}</option>
                  <option value="category">{{ $t('category') }}</option>
                </select>
              </CCol>
              <CCol class="pl-0 col-6">
                <template v-if="searchBy === 'category'">
                  <select id="stockOutDetailCategorySelect" v-model="selectedCategory" class="form-control text-dark no-border-radius-right">
                    <option selected value="">{{ $t('selectCategory') }}</option>
                    <option :id="'stockOutDetailCategoryOption' + index" v-for="(category, index) in dataCategory" :key="category.objectId" :value="category.objectId">
                      {{ category.name }}
                    </option>
                  </select>
                </template>
                <template v-else>
                  <input id="stockOutDetailSearchInput" class="form-control text-dark no-border-radius-right" v-model="keywordEntered" @input="searchProductPLU"
                    :placeholder="$t('searchProduct')" />
                </template>
              </CCol>

              <CCol class="p-0">
                <CButton id="stockOutDetailSearchButton" block color="success" v-if="loadingButton === true" v-on:click="searchProductPLU">
                  {{ $t('search') }}
                </CButton>
              </CCol>
              <CCol>
                <CButton id="stockOutDetailResetButton" class="btn btn-outline-secondary text-dark font-weight-normal"
                  style="font-weight: bold; color: black; width: inherit" v-on:click="reset">
                  {{ $t('reset') }}
                </CButton>
              </CCol>
            </CRow>
            <div style="max-height: 400px; overflow-y: auto;" class="mt-4">
              <CDataTable id="stockOutDetailTable" pagination :fields="fields" :items="autoCompleteResult" hover v-model="autoCompleteResult" border
                clickableRows @row-clicked="onSelectedAutoCompleteEvent">
                <template slot="selected-header">
                  <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                    <input v-model="all" type="checkbox" @change="setAll()" class="custom-control-input success cursor"
                      id="yyy" />
                    <label class="custom-control-label cursor" for="yyy">
                    </label>
                  </div>
                </template>
                <template #selected="{ item, index }">
                  <td :id="'stockOutDetailSelectRow' + index" style="vertical-align: middle;">
                    <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="item.selected"
                        @change="onSelectedAutoCompleteEvent(item)" :id="index" />
                      <label class="custom-control-label cursor" :for="index">
                      </label>
                    </div>
                  </td>
                </template>
                <template #name="{ item, index }">
                  <td style="vertical-align: middle;border-left: hidden;" :for="index">
                    <img v-if="item.remoteImagePath" :src="item.remoteImagePath"
                      style="border-radius: 2px;aspect-ratio: 4 / 3;" class="img-fluid" @error="noImgUrl" />
                    <div v-else style="border-radius: 2px" :style="{
    'aspect-ratio': '4 / 3',
    backgroundColor: item.indexColor,
  }"></div>
                  </td>
                </template>
                <template #nameproduct="{ item }">
                  <td class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.name }}
                  </td>
                </template>
                <template #categoryName="{ item }">
                  <td class="text-dark font-weight-normal" style="vertical-align: middle;border-left: hidden;">
                    {{ item.categoryName }}
                  </td>
                </template>
                <template #pluCode="{ item }">
                  <td class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.PLUCode }}
                  </td>
                </template>
                <template #price="{ item, index }">
                  <td :for="index" style="vertical-align: middle;border-left: hidden " v-if="item.ProductSKU.stdCost === '' ||
    item.ProductSKU.stdCost === undefined
    ">
                    ฿ 0
                  </td>
                  <td :for="index" style="vertical-align: middle;border-left: hidden " v-else>
                    ฿ {{ item.ProductSKU.stdCost }}
                  </td>
                </template>
                <template #onhanQTY="{ item, index }">
                  <td
                    class="text-right"
                    :for="index"
                    style="vertical-align: middle; border-left: hidden"
                    v-if="
                    item.ProductPLUStock.onhandQty === '' ||
                    item.ProductPLUStock.onhandQty === undefined
                  "
                >
                  N/A
                </td>
                <td
                  class="text-right"
                  :for="index"
                  style="vertical-align: middle; border-left: hidden"
                  v-else
                >
                  <div
                    v-if="item.ProductPLUStock.onhandQty < 0 && Number.isInteger(parseFloat(item.ProductPLUStock.onhandQty))"
                    class="text-danger"
                  >
                    {{ parseFloat(item.ProductPLUStock.onhandQty) }}
                  </div>
                  <div
                    v-else-if="item.ProductPLUStock.onhandQty < 0 && !Number.isInteger(parseFloat(item.ProductPLUStock.onhandQty))"
                    class="text-danger"
                  >
                    {{ parseFloat(item.ProductPLUStock.onhandQty).toFixed(3) }}
                  </div>
                  <div
                    v-else-if="!Number.isInteger(parseFloat(item.ProductPLUStock.onhandQty))"
                    class="text-dark"
                  >
                    {{ parseFloat(item.ProductPLUStock.onhandQty).toFixed(3) }}
                  </div>
                  <div v-else class="text-dark">
                    {{ parseFloat(item.ProductPLUStock.onhandQty) }}
                  </div>
                </td>
              </template>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </div>
          </CCardBody>
        </template>
        <template #footer-wrapper>
          <CRow class="justify-content-end col-md-12">
            <CCol col="12" lg="6" class="row">
              <div class="col-6">
                <CButton id="stockOutDetailConfirmButton" block color="success" v-if="loadingButton === true" v-on:click="onCheckboxChange">
                  {{ $t('confirm') }}
                </CButton>
                <CButton id="stockOutDetailConfirmButtonDisabled" block color="success" v-else-if="loadingButton === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </div>
              <div class="col-6">
                <CButton id="stockOutDetailCancelButton" class="btn btn-outline-secondary text-dark font-weight-normal"
                  style="font-weight: bold; width: inherit" @click="AddProductModal = false">
                  {{ $t('cancel') }}
                </CButton>
              </div>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
    <CRow>
      <CModal id="stockOutDetailAddCheckStockModal" :show.sync="AddCheckStockModal" centered :footer="footer" size="lg" style="max-width: 915px;">
        <template #header-wrapper class="header-modal">
          <header class="modal-header header-modal">
            <h4 class="modal-title font-weight-normal">
              {{ $t('selectCheckStockDoc') }}
            </h4>
            <CButton id="stockOutDetailCreateCheckStockButton" to="/inventory/check-stock/create" color="warning">
              <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>  {{ $t('createCheckStock') }}
            </CButton>
            <!-- <button type="button" aria-label="Close" class="close" @click="AddCheckStockModal = false">
              ×
            </button> -->
          </header>
        </template>
        <template #body-wrapper>
          <CCardBody>
            <div style="max-height: 400px; overflow-y: auto;">
              <CDataTable id="stockOutDetailTable" :fields="checkStockFields" :items="checkStockItem" hover border v-model="checkStockItem"
                clickableRows @row-clicked="onSelectCheckStockDoc">

                <!-- <template slot="selected-header">
                  <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                    <input v-model="all" type="checkbox" @change="setAll()" class="custom-control-input success cursor"
                      id="checkbox" />
                    <label class="custom-control-label cursor" for="checkbox">
                    </label>
                  </div>
                </template> -->
                <template slot="nameproduct-header">
                  <p></p>
                </template>
                <template #selected="{ item, index }">
                  <td :id="'stockOutDetailSelectedRow' + index" style="vertical-align: middle; text-align: center;">
                    <div class="custom-control custom-checkbox " style="padding-left: 2rem">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="item.selected"
                        @change="onSelectCheckStockDoc(item)" :id="index" />
                      <label class="custom-control-label cursor" :for="index">
                      </label>
                    </div>
                  </td>
                </template>
                <template #name="{ item }">
                  <td class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.note }}
                  </td>
                </template>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </div>
          </CCardBody>
        </template>
        <template #footer-wrapper>
          <CRow class="justify-content-end col-md-12">
            <CCol col="12" lg="6" class="row">
              <div class="col-6">
                <CButton id="stockOutDetailConfirmButton" block color="success" v-if="loadingButton === true" v-on:click="onCheckboxChange">
                  {{ $t('confirm') }}
                </CButton>
                <CButton id="stockOutDetailSaveButtonDisabled" block color="success" v-else-if="loadingButton === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </div>
              <div class="col-6">
                <CButton id="stockOutDetailCancelButton" class="btn btn-outline-secondary text-dark font-weight-normal" style="width: inherit"
                  @click="AddCheckStockModal = false">
                  {{ $t('cancel') }}
                </CButton>
              </div>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import axios from '@/services/local'
import util from '@/util/util'
import report from '@/services/report'
import exportStock from '@/print/export'
import permis from '@/util/permission'
import DataTable from '@/containers/DataTable'
import pos from '@/services/local'

export default {
  components: {
    Multiselect,
    DataTable
  },
  data() {
    return {
      isSuccess: false,
      alert: false,
      data: [],
      detail: {},
      items: {},
      itemList: [],
      member: {},
      searchBy: 'name',
      label: 'name',
      keyword: '',
      vatType: '',
      placeHolderInputText: 'พิมพ์ชื่อสินค้าที่ต้องการค้นหา .​.​.',
      autoCompleteResult: [],
      autoCompleteProgress: false,
      autoCompleteText: 'name',
      autoCompleteText2: 'PLUCode',
      autoCompleteText3: 'unitName',
      autoCompleteFieldId: 'alpha3Code',
      validateShop: '',
      validateItem: '',
      popupModal: false,
      footer: '',
      deleteModal: false,
      deleteFooter: '',
      isDeleted: '',
      loadingButton: true,
      duplicateItem: '',
      warning: '',
      customer: null,
      permission: false,
      AddProductModal: false,
      keywordEntered: '',
      selectedItems: [],
      all: false,
      isVat: false,
      checkStockItem: [],
      AddCheckStockModal: false,
      dataCategory: [],
      selectedCategory: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    isPermission() {
      return permis.findPermissionRead('inventory', '/inventory/stock-out')
    },
    isEdit() {
      return permis.findPermissionEdit('inventory', '/inventory/stock-out')
    },
    isExport() {
      return permis.findPermissionExport('inventory', '/inventory/stock-out')
    },
    isRemove() {
      return permis.findPermissionRemove('inventory', '/inventory/stock-out')
    },
    stockOutObjectId() {
      return this.$route.params.stockOutObjectId
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    datetime() {
      return moment(String(this.detail.date)).format('DD/MM/YYYY')
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    disabledField() {
      if (this.isDeleted === true) {
        return true
      } return false
    },
    vat() {
      let vat = 0;
      this.detail.items.forEach(item => {
        vat += this.calculateVat(item);
      });

      return vat;
    },
    vatCurrencyString() {
      return util.convertCurrency(this.vat);
    },
    grandTotal() {
      let grandTotal = ''
      let summary = this.netTotal || 0
      let vat = this.vat || 0

      if (this.isVat === false) {
        grandTotal = summary - this.detail.discount
      } else if (this.isVat === true) {
        grandTotal = summary + vat - this.detail.discount
      }

      return util.convertCurrency(grandTotal)
    },
    netTotal() {
      let total = 0;
      this.detail.items.forEach(item => {
        total += this.grandTotalItem(item);
      });
      return total;
    },
    netTotalCurrencyString() {
      return util.convertCurrency(this.netTotal)
    },
    documentItems() {
      const items = this.detail.items || []
      return items.sort((a, b) => a.orderIndex - b.orderIndex);
    },
    members() {
      const member = this.member || []
      let detail = []
      for (let i = 0; i < member.length; i++) {
        const item = member[i]
        const fullName = (item.firstname || item.name || '') + ' ' + (item.lastname || '')
        detail.push({
          id: item.id,
          objectId: item.objectId,
          name: fullName,
        })
      }
      return detail
    },
    setExportdata() {
      let data = this.detail
      let items = []
      let dataitem = this.detail.items
      let status = ''
      if (data.deleted_at != null) {
        status = 'ยกเลิก'
      } else {
        status = 'ใช้งาน'
      }
      for (let i = 0; i < dataitem.length; i++) {
        items.push({
          productPLU: dataitem[i].productPLU,
          cost: util.convertCurrency(dataitem[i].cost),
          discount: util.convertCurrency(dataitem[i].discount),
          total: util.convertCurrency(dataitem[i].total),
          quantity: dataitem[i].quantity,
        })
      }
      // let num = util.convertCurrency(this.grandTotal)
      let countString = util.ThaiBaht(this.grandTotal)
      let shops = this.shops.find((i) => i.objectId === this.shopObjectId)
      let detail = {
        netTotal: this.netTotal,
        grandTotal: this.grandTotal,
        vat: this.vat,
        datetime: this.datetime,
        items: items,
        shop: shops,
        detail: this.detail,
        status: status,
        txtgrandTotal: countString,
        discount: util.convertCurrency(this.detail.discount),
        customer: this.customer || {},
      }
      return detail
    },
    fields() {
      return [
        {
          key: 'selected',
          label: this.$i18n.t('selectd'),
          _style: 'width:5%; vertical-align: middle;',
          _classes: 'text-left font-weight-normal',
        },
        {
          key: 'name',
          label: '',
          _style: 'width:7%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left font-weight-normal',
        },
        {
          key: 'nameproduct',
          label: this.$i18n.t('product'),
          _style: 'width:30%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left font-weight-normal',
        },
        {
          key: 'pluCode',
          label: this.$i18n.t('pluCode'),
          _style: 'width:25%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left font-weight-normal',
        },
        {
          key: 'categoryName',
          label: this.$i18n.t('category'),
          _style: 'width:23%; vertical-align: middle;',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'onhanQTY',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-left font-weight-normal text-dark',
          _style: 'width:30%; vertical-align: middle;border-left: hidden',
        },
      ]
    },
    shop: {
      get() {
        return this.shops.find((shop) => shop.objectId === this.shopObjectId) || {};
      },
    },
    checkStockFields() {
      return [
        {
          key: 'selected',
          label: this.$i18n.t('selectd'),
          _style: 'width:10%; vertical-align: middle;',
          _classes: 'text-center text-dark font-weight-normal',
        },
        {
          key: 'name',
          label: this.$i18n.t('checkStockName'),
          _style: 'width:90%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left text-dark font-weight-normal',
        },
      ]
    },
  },
  created() {
    // this.shop = this.shops.find((i) => i.objectId === this.shop)
    this.getStockOutDetail()
    this.getMember()
    this.getCheckStock()
    this.getCategory()
  },
  methods: {
    ...util,
    getCategory() {
      const uid = `${localStorage.getItem('shopsUid')}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        page: 1,
        limit: 1000,
      };
      const headers = {
          shopObjectId: shopObjectId,
      }

      pos
        .get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId, {
          params,headers
        })
        .then((res) => {
          let detail = []
          let data = res.data.data
          for (let i = 0; i < data.length; i++) {
            if (data[i].enabled === true) {
              detail.push({
                id: data[i].id,
                name: data[i].name,
                objectId: data[i].objectId,
              })
            }
          }
          this.dataCategory = detail
          })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    },
    getCheckStock(page = 1) {
      this.loadingButton = false
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      // const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      // const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      axios({
        url: '/api/v2.2/' + uid + '/CheckStockDocument',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          this.data = this.data.filter(item => item.shop.objectId === this.shopObjectId);
          this.loadingButton = true
          let checkStockDoc = this.data.map(item => {
            return { ...item, selected: false };
          });
          this.checkStockItem = checkStockDoc
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onSelectCheckStockDoc(item) {
      item.selected = !item.selected
      let selectedDoc = [];
      if (item.items) {
        item.items.forEach(docItem => {
          let newItem = {
                PLUCode: docItem.productPLU?.PLUCode || '-',
                name: docItem.productPLU?.name || '-',
                ProductPLU: docItem.productPLU || {},
                ProductSKU: docItem.productSKU || {},
                id: docItem.productPLUId || '',
                objectId: docItem.productPLU?.objectId || '',
                unit: docItem.productPLU?.unit || {},
                unitName: docItem.productPLU?.unit?.name !== undefined 
                    ? docItem.productPLU.unit.name 
                    : (docItem.unit?.name !== undefined 
                        ? docItem.unit.name 
                        : '-'),
                ProductPLUStock: docItem.ProductPLUStock || {},
                SKURatio: docItem.productPLU?.SKURatio || 1,
                checkCount: docItem.quantity || 0,
                shop: docItem.shop || {},
                uid: this.uid || '',
                vatType: docItem.productSKU?.vatType || 'V',
                indexColor: util.generateColor(docItem.indexColor)
          };
          const matchedIndex = this.autoCompleteResult.findIndex(
            (autoItem) =>
              autoItem.objectId === docItem.productPLU?.objectId
          );

          if (matchedIndex !== -1) {
            const matchedItem = this.autoCompleteResult[matchedIndex];
            newItem.ProductSKU.stdCost = matchedItem.stdCost || matchedItem.ProductSKU?.stdCost || 0;
          } else {
            newItem.ProductSKU.stdCost = 0;
          }
          if (item.selected) {
            newItem.selected = true;
          }

          selectedDoc.push(newItem);
        });
      }
      this.selectedItems.push(...selectedDoc);
    },
    getDateLocale() {
      if (this.$i18n.locale == 'en') {
        return { lang: 'en' }
      } else {
        return { lang: 'de' }
      }
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    AddModal() {
      this.keywordEntered = ''
      this.autoCompleteResult = []
      this.all = false
      this.searchProductPLU()
      this.AddProductModal = true
    },
    reset() {
      this.selectedItems = []
      this.all = false
      this.keywordEntered = ''
      this.searchBy = 'name'
      this.selectedCategory = ''
      this.searchProductPLU()
    },
    handlePopup() {
      if (this.detail.shop.length === 0) {
        this.popupModal = false
        this.validateShop = this.$i18n.t('selectShopPlease')
      } else {
        this.validateShop = ''
      }

      if (this.detail.items.length === 0) {
        this.popupModal = false
        this.validateItem = this.$i18n.t('selectProductPlease')
      } else {
        this.validateItem = ''
      }

      if (this.detail.items.length != 0 && this.detail.shop.length != 0) {
        this.popupModal = true
      }
    },
    removeProduct(i) {
      let items = this.detail.items
      let newItems = items.filter((item) => item.orderIndex != i.orderIndex)
      this.detail.items = newItems
      let index = this.autoCompleteResult.findIndex(
        (x) => x.objectId == i.productPLU.objectId
      )
      if (index != -1) {
        this.autoCompleteResult[index].selected = false
      }
      let find = this.selectedItems.findIndex(
        (x) => x.objectId === i.productPLU.objectId
      )
      if (find != -1) {
        this.selectedItems.splice(find, 1)
      }
    },
    totalQuantity() {
      let totalQty = 0;

      for (const item of this.detail.items) {
        totalQty += item.quantity;
      }
      return totalQty;
    },
    calculateDiscount() {
      const totalQty = this.totalQuantity();
      let discountPerQty = 0;
      if (totalQty !== 0) {
        discountPerQty = this.detail.discount / totalQty;
      } else {
        discountPerQty = 0;
      }

      return discountPerQty
    },
    grandTotalItem(item) {
      let total = 0
      if (item.vatType === 'N') {
        total = Number(item.quantity) * Number(item.cost) - Number(item.discount)
      }
      if (item.vatType === 'V') {
        total = Number(item.quantity) * Number(item.cost) - Number(item.discount)
      } else {
        total = (Number(item.quantity) * Number(item.cost)) - Number(item.discount)
      }
      return item.total = total
    },
    grandTotalItemDisperQty(item) {
      let totalincvat = 0;
      let discountPerQty = this.calculateDiscount();

      const ProductSKU = item.ProductSKU
      const vatType = item.vatType || ProductSKU.vatType 
      const quantity = item.quantity
      const cost = Number(item.cost)
      const discount = item.discount
      if ( item == null){
        return 0
      }
      if (vatType == 'V') {
        totalincvat = (quantity * cost) - discount - (quantity * discountPerQty);
        item.total = totalincvat;
        return totalincvat
      } else {
        totalincvat = (quantity * cost) - discount - (quantity * discountPerQty);
        item.total = totalincvat;
        return totalincvat
      }
    },
    calculateVat(item) {
      let vat = 0;
      let itemVatType = item.vatType || item.ProductSKU.vatType || item.productSKU.vatType
      if (itemVatType === 'V' && this.isVat === false) {
        vat = (this.grandTotalItemDisperQty(item) * 7) / 107;
      }
      else if (itemVatType === 'V' && this.isVat === true) {
        vat = this.grandTotalItemDisperQty(item) * (7 / 100);
      }
      else if (itemVatType === undefined && this.isVat === false) {
        vat = (this.grandTotalItemDisperQty(item) * 7) / 107;
      }
      else if (itemVatType  === undefined && this.isVat === true) {
        vat = this.grandTotalItemDisperQty(item) * (7 / 100);
      }
      return vat;
    },
    getStockOutDetail() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      const stockOutObjectId = this.$route.params.stockOutObjectId
      const params = {
        shopObjectId: shopObjectId,
      }
      let detail = []
      let itemList = []
      axios
        .get('/api/v1.0/' + uid + '/getstockout/' + stockOutObjectId, {
          params,
        })
        .then(async (res) => {

          const responseData = res.data || {}
          const data = responseData.data || {}
          const documents = data.documents
          this.customer = documents.customer || null

          this.detail = documents
          if (this.detail.deleted_at !== null) {
            this.isDeleted = true
          } else {
            this.isDeleted = false
          }
          this.items = this.detail.items
          this.isVat = !this.detail.isVatIncluded
          let createdAt = this.detail.created_at

          this.detail.items.forEach((item) => {
            const productPLU = item.ProductPLU || {}
            if (productPLU.remoteImagePath !== undefined || productPLU.remoteImagePath !== '') {
              item.remoteImagePath = productPLU.remoteImagePath
            }
            const ProductSKU = item.ProductSKU || {}
            const productPLUId = item.ProductPLUId || null
            const productSKU = item.productSKU
            const quantity = item.quantity || 0
            const shop = item.shop
            const vat = productSKU.vatType || item.vatType
            let cost = 0;
            if (!isNaN(item.cost) && item.cost !== null) {
              cost = parseFloat(item.cost)
            } else {
              cost = productSKU.stdCost || ProductSKU.stdCost || 0
            } 
            if (productPLU.lastedAdjust !== undefined) {
              let lasteadjust = productPLU.lastedAdjust
              let dt = moment(createdAt)
              let lastDt = moment(lasteadjust)
              let isLastedAdjust = dt.isBefore(lastDt)
              item.isLastedAdjust = isLastedAdjust
            } else {
              item.isLastedAdjust = false
            }
            item.indexColor = util.generateColor(productPLU.indexColor || 0)
            detail.push(item)
            itemList.push({
              PLUCode: productPLU.PLUCode,
              name: productPLU.name,
              ProductPLU: productPLU,
              ProductSKU: ProductSKU,
              id: productPLUId,
              objectId: productPLU.objectId,
              unit: productPLU.unit,
              unitName: productPLU.unit.name,
              // ProductPLUStock: ProductPLUStock,
              SKURatio: productPLU.SKURatio,
              quantity: quantity,
              shop: shop,
              uid: uid,
              vatType: vat,
              selected: true,
              cost: cost
            })
          })
          this.detail.items = detail
          this.selectedItems = itemList
          if (this.detail.deleted_at != null) {
            this.isDeleted = true
          } else {
            this.isDeleted = false
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getMember() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      const params = {
        shopObjectId: shopObjectId,
      }
      axios
        .get('/api/v1.0/' + uid + '/getmember', { params })
        .then((res) => {
          this.member = res.data.data.documents
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onSelectedAutoCompleteEvent(item) {
      item.selected = !item.selected
      if (item.selected) {
        this.selectedItems.push(item)
      } else {
        let i = this.selectedItems.findIndex(
          (x) => x.objectId === item.objectId
        )
        if (i != -1) {
          this.selectedItems.splice(i, 1)
        }
      }
      let countItem = this.autoCompleteResult.length
      let coutselect = 0
      this.autoCompleteResult.forEach(function (item) {
        if (item.selected) {
          coutselect = coutselect + 1
        }
      })
      if (countItem === coutselect) {
        this.all = true
      } else {
        this.all = false
      }
    },
    searchProductPLU() {
      // if (this.detail.shopId === undefined || this.detail.shopId === null) {
      //   this.validateShop = this.$i18n.t('selectShopPlease');
      // } else {
      //   this.validateShop = "";
      // }

      const uid = this.uid
      const shopId = this.detail.shopId

      let params = {}
      if (this.searchBy === 'category' && this.selectedCategory) {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          categoryObjectId: this.selectedCategory,
        }
      } else if (this.keywordEntered != '') {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          searchBy: this.searchBy,
          keyword: this.keywordEntered,
        }
      } else {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          searchBy: this.searchBy,
        }
      }

      this.label = this.searchBy
      this.autoCompleteResult = []
      this.autoCompleteProgress = false
      let selectedItems = this.selectedItems
      this.autoCompleteProgress = true

      axios
        .get('/api/v1.0/' + uid + '/getproductpluwithstock', { params })
        .then((response) => {
          this.alert = false
          let newData = []

          response.data.data.forEach(function (item) {
            if (selectedItems.length > 0) {
              let i = selectedItems.findIndex(
                (x) => x.objectId === item.objectId
              )
              if (i == -1) {
                item.selected = false
              } else {
                item.selected = true
              }
            } else {
              item.selected = false
            }
            const unit = item.unit || {}
            const category = item.category || {}
            item.unitName = unit.name || '-'
            item.categoryName = category.name || '-'
            const ProductSKU = item.ProductSKU
            item.vatType = ProductSKU.vatType
            item.indexColor = util.generateColor(item.ProductSKU.indexColor)
            if (item.selected == false) {
              newData.push(item)
            }
          })
          this.autoCompleteResult = newData
          this.autoCompleteProgress = false
        })
        .catch((e) => {
          console.log(e)
          this.alert = true
          this.autoCompleteProgress = false
          this.autoCompleteResult = []
        })
    },
    formSubmit(e) {
      this.loadingButton = false
      e.preventDefault()
      const plan = this.users.currentPlan
      let discount = ''

      if (typeof this.detail.discount === 'string') {
        discount = util.currencyStringToNumber(this.detail.discount)
      } else {
        discount = this.detail.discount
      }

      this.detail.items.forEach(function (item) {
        delete item.remoteImagePath
        delete item.indexColor
        item.cost = Number(item.cost)
      })
      const data = {
        objectId: this.detail.objectId,
        id: this.detail.id,
        billNo: this.detail.billNo,
        date: this.detail.date,
        discount: discount,
        grandTotal: util.currencyStringToNumber(this.grandTotal),
        isVatIncluded: !this.isVat,
        netTotal: this.netTotal,
        note: this.detail.note,
        shop: {
          id: this.detail.shop.id,
          objectId: this.detail.shop.objectId,
          shopName: this.detail.shop.shopName,
          branchName: this.detail.shop.branchName,
        },
        shopId: this.detail.shop.id,
        stockDocType: 2,
        vatBase: 7,
        uid: this.uid,
        vat: this.vat,
        items: this.detail.items,
        stockNo: this.detail.stockNo,
        vatType: this.vatType,
      }
      if (this.customer != null) {
        data['customer'] = this.customer
        data['customerId'] = this.customer.id || ''
      }

      if (data.shopId === undefined) {
        this.validateShop = this.$i18n.t('selectShopPlease')
      } else {
        this.validateShop = ''
      }

      if (data.items.length === 0) {
        this.validateItem = this.$i18n.t('selectProductPlease')
      } else {
        this.validateItem = ''
      }

      if (data.shopId != undefined && data.items.length != 0) {
        axios({
          method: 'post',
          url: '/api/v1.0/' + data.uid + '/stockdocument/save',
          params: { shopObjectId: data.shopObjectId, plan: plan },
          data: data,
        })
          .then(() => {
            // setTimeout(() => {
            this.isSuccess = true
            this.$router.push('/inventory/stock-out')
            // }, 5000);
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    deleteStockDocument(stockDocumentObjectId) {
      this.loadingButton = false
      const uid = this.uid
      const plan = this.users.currentPlan

      axios({
        method: 'post',
        url:
          '/api/v1.0/' + uid + '/deletestockdocument/' + stockDocumentObjectId,
        params: { plan: plan },
      })
        .then(() => {
          setTimeout(() => {
            (this.deleteModal = false),
              this.$router.push('/inventory/stock-out')
          }, 5000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportReport() {
      const setdata = this.setExportdata
      const uid = `${localStorage.getItem('uid')}`
      let html = exportStock.exportStockOutDetail(setdata)
      let data = {
        ref: this.shopObjectId,
        timezone: 'Asia/Bangkok',
        html: html,
      }
      let name = 'รายการจ่ายออกสินค้า_' + this.detail.stockNo + '.pdf'
      report({
        method: 'post',
        url: '/print/v1.0/' + uid + '/htmltopdf/a4',
        data: data,
        responseType: 'blob', // arraybuffer
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
      })
    },
    setAll() {
      let data = this.autoCompleteResult
      let select = this.selectedItems
      if (this.all) {
        data.forEach(function (item) {
          item.selected = true
          select.push(item)
        })
      } else {
        data.forEach(function (item) {
          item.selected = false
          let i = select.findIndex((x) => x.objectId === item.objectId)
          if (i != -1) {
            select.splice(i, 1)
          }
        })
      }
      this.selectedItems = select
      this.autoCompleteResult = data
    },
    onCheckboxChange() {
      let data = this.selectedItems
      let items = this.detail.items
      data.forEach(function (item, index) {
        let stdCost = 0
        let SKURatio = 1
        let totalCost = 0

        if (item.selected) {
          if (
            item.ProductSKU.stdCost === undefined ||
            item.ProductSKU.stdCost === null
          ) {
            stdCost = 0
          } else {
            stdCost = item.ProductSKU.stdCost
          }
          if (item.SKURatio == undefined) {
            SKURatio = 1
          } else {
            SKURatio = item.SKURatio
          }

          totalCost = Number(SKURatio) * Number(stdCost);
          let checkCount = item.checkCount;
          let quantity = checkCount !== undefined ? checkCount : 0;
          let vatType = item.vatType || item.ProductSKU.vatType
          
          if (items.length > 0) {
            let i = items.findIndex(
              (x) => x.productPLU.objectId === item.objectId
            )
            if (i == -1) {
              items.push({
                cost: totalCost,
                discount: 0,
                orderIndex: index,
                productPLU: {
                  id: item.id,
                  objectId: item.objectId,
                  PLUCode: item.PLUCode,
                  name: item.name,
                  unit: item.unit,
                  SKURatio: SKURatio,
                },
                productPLUId: item.id,
                quantity: quantity,
                total: 0,
                productSKUObjectId: item.ProductSKU.objectId,
                productSKU: item.ProductSKU || item.productSKU  || {},
                remoteImagePath: item.remoteImagePath,
                indexColor: item.indexColor,
                vatType: vatType

              })
            }
          } else {
            items.push({
              cost: totalCost,
              discount: 0,
              orderIndex: index,
              productPLU: {
                id: item.id,
                objectId: item.objectId,
                PLUCode: item.PLUCode,
                name: item.name,
                unit: item.unit,
                SKURatio: SKURatio,
              },
              productPLUId: item.id,
              quantity: quantity,
              total: 0,
              productSKUObjectId: item.ProductSKU.objectId,
              productSKU: item.ProductSKU || item.productSKU  || {},
              remoteImagePath: item.remoteImagePath,
              indexColor: item.indexColor,
              vatType: vatType

            })
          }
        } else {
          let index = items.findIndex(
            (x) => x.productPLU.objectId === item.objectId
          )
          if (index != -1) {
            items.splice(index, 1)
          }
        }
      })
      this.detail.items = items
      this.AddProductModal = false
      this.AddCheckStockModal = false
      this.all = false
    },
    toDateString(date) {
      return moment(String(date)).format('DD/MM/YYYY HH:mm:ss')
    },
    isDeletedItem(item) {
      return item.deleted_at != null
    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
}
</script>

<style>
/* Hidden arrow  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="../../../assets/styles/inventory.css" scoped></style>
